import AddressList from '~/src/components/profile/account/addresses/AddressList'
import DocumentTitleInjector from '~/src/components/root/DocumentTitleInjector'
import ContentContainer from '~/src/components/utils/layout/ContentContainer'

const Addresses = () => {
  return (
    <ContentContainer>
      <DocumentTitleInjector pageName={'ADDRESSES'} />
      <AddressList />
    </ContentContainer>
  )
}

export default Addresses
