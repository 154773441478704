import { Typography } from '@mui/material'
import { Address } from '@eo-storefronts/eo-core'

interface Props {
  address: Address,
}

const AddressCardDetails = ({ address }: Props) => (
  <div>
    <Typography
      variant='body2'
      className='eo-ellipsis'
    >
      {`${address.street}, ${address.house_number}`}
      {address.bus && ` | ${address.bus}`}
    </Typography>

    <Typography
      variant='body2'
      className='eo-ellipsis'
    >
      {`${address.zip_code} ${address.locality} - ${address.country?.name}`}
    </Typography>
  </div>
)

export default AddressCardDetails
