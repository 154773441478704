import { Box } from '@mui/material'
import AddressGrid from '~/src/components/customers/addresses/AddressGrid'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { CUSTOMER_STATE } from '~/src/stores/customer'
import AddressAddButton from '~/src/components/profile/account/addresses/AddressAddButton'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import FormTitle from '~/src/components/utils/form/FormTitle'

const AddressList = () => {
  const { t } = useTranslations()
  const customer = useEoValue(CUSTOMER_STATE)
  const theme = useCustomMuiTheme()

  if (!customer?.addresses) {
    return null
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 5
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            gap: 2
          }
        }}
      >
        <FormTitle
          title={t('profile.address.your_addresses')}
          subTitle={t('profile.address.your_addresses_description')}
        />
        {!!customer.addresses.length && <AddressAddButton />}
      </Box>
      <AddressGrid addresses={customer.addresses} />
    </Box>
  )
}

export default AddressList
