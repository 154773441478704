import { Address } from '@eo-storefronts/eo-core'
import { Grid } from '@mui/material'
import AddressGridItem from '~/src/components/customers/addresses/AddressGridItem'
import NoAddressesFound from '~/src/components/customers/addresses/NoAddressesFound'

interface Props {
  addresses: Address[],
}

const AddressGrid = ({ addresses }: Props) => {
  if (!addresses.length) {
    return <NoAddressesFound />
  }

  return (
    <Grid
      container
      spacing={1.5}
    >
      {addresses.map((address: Address) => (
        <AddressGridItem
          key={address.id}
          address={address}
        />
      ))}
    </Grid>
  )
}

export default AddressGrid
