import StarBorderIcon from '@mui/icons-material/StarBorder'
import StarIcon from '@mui/icons-material/Star'
import { Button } from '@mui/material'
import { Address } from '@eo-storefronts/eo-core'
import { useCustomerAddress } from '~/src/hooks/customer/useCustomerAddress'

interface Props {
  address: Address,
}

const  FavouriteAddressButton = ({ address }: Props) => {
  const { updateAddress } = useCustomerAddress()

  const handleOnEditClick = async () => {
    try {
      await updateAddress({
        ...address,
        is_default: !address.is_default
      } as Address, false)
    } catch (e) { /* Nothing */
    }
  }

  return (
    <Button
      sx={{
        position: 'absolute',
        minWidth: '32px',
        top: 5,
        right: 5,
        color: 'secondary.contrastText'
      }}
      onClick={handleOnEditClick}
    >
      {!address.is_default && <StarBorderIcon />}
      {address.is_default && <StarIcon />}
    </Button>
  )
}

export default FavouriteAddressButton
