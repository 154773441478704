import { Box, Container, Typography } from '@mui/material'
import Title from '~/src/components/mui-wrappers/Title'
import AddressAddButton from '~/src/components/profile/account/addresses/AddressAddButton'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useTranslations } from '~/src/hooks/useTranslations'

const NoAddressesFound = () => {
  const { t } = useTranslations()
  const theme = useCustomMuiTheme()

  return (
    <Container sx={{
      width: '30%',
      pt: 5,
      [theme.breakpoints.down('lg')]: {
        width: '30%'
      },
      [theme.breakpoints.down('md')]: {
        width: '80%'
      },
      [theme.breakpoints.down('sm')]: {
        width: '90%'
      }
    }}>
      <Title variant='h4'>
        {t('addresses.no_addresses')}
      </Title>
      <Typography
        variant='h6'
        color='text.secondary'>
        {t('addresses.no_addresses_description')}
      </Typography>
      <Box sx={{
        mt: 3
      }}>
        <AddressAddButton />
      </Box>
    </Container>
  )
}

export default NoAddressesFound
