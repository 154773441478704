import { Address } from '@eo-storefronts/eo-core'
import { Grid } from '@mui/material'
import AddressCard from '~/src/components/profile/account/addresses/AddressCard'

interface Props {
  address: Address,
}

const AddressGridItem = ({ address }: Props) => {
  return (
    <Grid
      key={address.id}
      item
      xs={12}
      md={3}
      sm={6}
    >
      <AddressCard
        address={address}
        key={address.id}
      />
    </Grid>
  )
}

export default AddressGridItem
